import { styled, css } from '@stitches/react';
import { Colors, Radius, Shadows, Sizes, Spaces } from '@stonksfi/constants';

export const CourseContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
  gap: Spaces.medium,
});

export const ButtonsRow = styled('div', {
  justifySelf: 'flex-end',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  borderTop: `2px solid ${Colors.grey6}`,
  marginTop: Spaces.medium,
  padding: Spaces.medium,
  width: '100%',
});

export const ControlsContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `0 ${Spaces.medium}`,
});

export const ButtonClass = css({
  padding: `${Spaces.tiny} 0`,
});
