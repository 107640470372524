import React, { useCallback } from "react";
import {
  QueryClient,
  QueryClientProvider,
  QueryFunctionContext,
} from "react-query";
import { BACKEND } from "../../../apps/dashboard/src/config";
import { useUserContext } from "../useUserContext";

export const QueryClientProviderWithAuth = ({ children }: any) => {
  const { accessToken } = useUserContext();
  console.log(accessToken, "xx access token");
  const defaultQueryFn = useCallback(
    async ({ queryKey }: QueryFunctionContext<any>) => {
      const res = await fetch(`${BACKEND}${queryKey[0]}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (!res.ok) {
        throw new Error("Network res was not ok");
      }

      return res.json();
    },
    [accessToken]
  );

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultQueryFn,
        retry: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
