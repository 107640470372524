import {
  ModuleDisplaySetting,
  ModuleKind,
} from '@stonksfi/components/Layout/types';
import {
  PageHeader,
  NewsOTDModule,
  ConceptOTDModule,
  DailyTasksModule,
  TopicProgressModule,
  Course,
} from '@stonksfi/modules';
import { convertPxToNumber } from '@stonksfi/utils';
import { themeObj } from '../../../styles/styling';

const { theme } = themeObj;

export const mainConfig: ModuleDisplaySetting[] = [
  {
    name: 'main',
    direction: 'column',
    kind: ModuleKind.CONTAINER,
    fillHeight: true,
    children: [
      {
        name: 'PAGE_HEADER',
        kind: ModuleKind.ELEMENT,
        render: <PageHeader />,
      },
      {
        name: 'TASKS_CONTENT',
        fillHeight: true,
        fillWidth: true,
        kind: ModuleKind.ELEMENT,
        render: <Course />,
      },
    ],
  },
];
