import {
  ModuleDisplaySetting,
  ModuleKind,
} from '@stonksfi/components/Layout/types';
import {
  SideBar,
  NewsOTDModule,
  ConceptOTDModule,
  DailyTasksModule,
  TopicProgressModule,
} from '@stonksfi/modules';
import { convertPxToNumber } from '@stonksfi/utils';
import { themeObj } from '../../../styles/styling';

const { theme } = themeObj;

export const mainConfig: ModuleDisplaySetting[] = [
  {
    name: 'main',
    direction: 'row',
    kind: ModuleKind.CONTAINER,
    fillHeight: true,
    children: [
      {
        name: 'SIDE_BAR',
        kind: ModuleKind.ELEMENT,
        render: <SideBar />,
      },
      {
        name: 'TASKS_CONTENT',
        fillHeight: true,
        width: 500,
        kind: ModuleKind.CONTAINER,
        paddingRight: convertPxToNumber(theme.space.large),
        direction: 'column',
        children: [
          {
            name: 'DAILY_TASKS',
            paddingLeft: convertPxToNumber(theme.space.medium),
            paddingTop: convertPxToNumber(theme.space.medium),
            paddingBottom: convertPxToNumber(theme.space.large),
            kind: ModuleKind.ELEMENT,
            render: <DailyTasksModule />,
          },
          {
            name: 'TOPIC_PROGRESS',
            paddingTop: convertPxToNumber(theme.space.medium),
            paddingLeft: convertPxToNumber(theme.space.medium),
            fillHeight: true,
            kind: ModuleKind.ELEMENT,
            render: <TopicProgressModule />,
          },
        ],
      },
      {
        name: 'LEARNING_CONTENT',
        width: 700,
        fillHeight: true,
        paddingTop: convertPxToNumber(theme.space.large),
        kind: ModuleKind.CONTAINER,
        direction: 'column',
        children: [
          {
            name: 'CONCEPT_OTD_MODULE',
            paddingLeft: convertPxToNumber(theme.space.medium),
            paddingTop: convertPxToNumber(theme.space.medium),
            kind: ModuleKind.ELEMENT,
            render: <ConceptOTDModule />,
          },
          {
            name: 'NEWS_OTD_MODULE',
            paddingLeft: convertPxToNumber(theme.space.medium),
            paddingTop: convertPxToNumber(theme.space.medium),
            paddingBottom: convertPxToNumber(theme.space.large),
            fillHeight: true,
            kind: ModuleKind.ELEMENT,
            render: <NewsOTDModule />,
          },
        ],
      },
    ],
  },
];
