import { styled, css } from '@stitches/react';
import { Colors, Radius, Spaces } from '@stonksfi/constants';

export const StyledTooltipTrigger = styled('span', {
  width: 'fit-content',
  height: 'fit-content',
});

export const PopoverWrapperClassName = css({
  border: `2px solid ${Colors.grey6}`,
  background: 'red',
});

export const StyledPopover = styled('div', {
  width: 'fit-content',
  height: 'fit-content',
  maxWidth: '$cardHeight',
  padding: Spaces.tiny,
  borderRadius: Radius.tiny,
  borderLeft: `3px solid ${Colors.grey6}`,
  borderTop: `3px solid ${Colors.grey6}`,
});
