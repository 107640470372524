/* eslint-disable max-len */
import React, { ReactNode } from 'react';
import { SquareButton } from '@stonksfi/components';
import { history } from '@stonksfi/utils';
import { ModuleConfig } from '../types';
import {
  StyledDailyTasks,
  StyledRow,
  StyledRowWithSpaceBetween,
} from './style';

interface Props {
  config?: ModuleConfig;
}

const NewsModule = (props: Props) => {
  const { config } = props;
  const redirectToReadPage = () => {
    history.push('/course');
  };

  return (
    <StyledDailyTasks>
      <StyledRowWithSpaceBetween>
        <h1>Today</h1>
        <h2>7 🔥</h2>
      </StyledRowWithSpaceBetween>
      <StyledRow>
        <SquareButton icon='1' onClick={redirectToReadPage}>
          READ
        </SquareButton>
        <SquareButton icon='2'>PLAY</SquareButton>
        <SquareButton icon='3'>QUIZ</SquareButton>
      </StyledRow>
    </StyledDailyTasks>
  );
};

export default NewsModule;
