import { styled } from '@stitches/react';
import {
  Colors,
  FontSizes,
  FontWeights,
  Radius,
  Shadows,
  Sizes,
  Spaces,
} from '@stonksfi/constants';

export const StyledSquareButton = styled('button', {
  fontSize: FontSizes.content16,
  color: Colors.primaryColor9,
  fontWeight: FontWeights.bold,
  background: Colors.primaryColor1,
  borderRadius: Radius.small,
  borderTop: `3px solid ${Colors.primaryColor9}`,
  borderLeft: `3px solid ${Colors.primaryColor9}`,
  boxShadow: Shadows.small,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  '&:hover': {
    background: Colors.primaryColor2,
  },

  variants: {
    size: {
      small: {
        width: Sizes.squareSide,
        height: Sizes.squareSide,
      },
    },
  },
});

export const StyledIconText = styled('div', {
  fontSize: FontSizes.title54,
  lineHeight: '54px',
  marginBottom: Spaces.tiny,
  color: Colors.primaryColor9,
  fontWeight: FontWeights.extrabold,
});
