import { styled, css } from '@stitches/react';
import { Spaces } from '@stonksfi/constants';

export const StyledTooltipTrigger = styled('span', {
  width: 'fit-content',
  height: 'fit-content',
});

export const StyledPopover = styled('div', {
  padding: Spaces.tiny,
  width: 'fit-content',
  height: 'fit-content',
  maxWidth: '$cardHeight',
});
