import React, { ReactNode } from 'react';
import ContentGuide, {
  ContentGuideProps,
} from '@stonksfi/components/ContentGuide';
import { Button, Steps } from '@stonksfi/components';
import { ModuleConfig } from '../types';
import {
  ButtonClass,
  ButtonsRow,
  ControlsContainer,
  CourseContainer,
} from './style';

interface Props {
  config?: ModuleConfig;
}

const Course = (props: Props) => {
  const { config } = props;
  // TODO: call backend here.
  const [currentStep, setCurrentStep] = React.useState(0);
  const contentGuides: ContentGuideProps[] = [
    {
      contentGuideType: 'default',
      contentImg: 'https://via.placeholder.com/150',
      content: `
      1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco l 
      1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco l
      1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco l
      1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco l
      1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco l
      1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco l
      1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco l
      1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco l
      1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco l
      1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco l`,
    },
    {
      contentGuideType: 'default',
      contentImg: 'https://via.placeholder.com/150',
      content: `
      2 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco l`,
    },
    {
      contentGuideType: 'default',
      contentImg: 'https://via.placeholder.com/150',
      content: `
      3 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco l`,
    },
  ];
  return (
    <CourseContainer>
      <ContentGuide {...contentGuides[currentStep]} />
      <ControlsContainer>
        <Steps
          totalSteps={contentGuides.length}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
        <ButtonsRow>
          <Button
            type='whiteStrong'
            size='small'
            className={ButtonClass()}
            onClick={() => {
              if (currentStep > 0) {
                setCurrentStep(currentStep - 1);
              }
            }}
          >
            PREVIOUS
          </Button>
          <Button
            type='primaryStrong'
            size='small'
            className={ButtonClass()}
            onClick={() => {
              if (currentStep < contentGuides.length - 1) {
                setCurrentStep(currentStep + 1);
              }
            }}
          >
            NEXT
          </Button>
        </ButtonsRow>
      </ControlsContainer>
    </CourseContainer>
  );
};

export default Course;
