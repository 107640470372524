// Basic Imports
import React, { ReactNode } from 'react';
import { Sidenav as Component, Nav } from 'rsuite';
import { history } from '@stonksfi/utils';
import {
  StyledSideBar,
  StyledSideBarBody,
  StyledNavItem,
  StyledPageHeaderLeft,
  LabelCss,
} from './styles';
import ColouredLabel from '../ColouredLabel';

export type SidenavOption = {
  name: string;
  icon: ReactNode;
  path: string;
  secondaryOptions?: SidenavOption[];
};

export interface SidenavProps {
  options: SidenavOption[];
}

const Sidenav = (props: SidenavProps) => {
  const { options } = props;
  const handleChangePage = (newPage: string) => {
    history.push(newPage);
  };

  return (
    <StyledSideBar>
      <StyledPageHeaderLeft>
        <h1>
          {' '}
          stonks.fi{' '}
          <ColouredLabel label='DEMO' type='green' className={LabelCss()} />
        </h1>
        <Nav />
      </StyledPageHeaderLeft>
      <StyledSideBarBody>
        {options.map((option: SidenavOption, index: number) => {
          return (
            <StyledNavItem
              onClick={() => handleChangePage(option.path)}
              key={index}
              selected={option.path === history.location.pathname}
            >
              {option.icon} {option.name}
            </StyledNavItem>
          );
        })}
      </StyledSideBarBody>
    </StyledSideBar>
  );
};

export default Sidenav;
