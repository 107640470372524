import { styled, css } from '@stitches/react';
import { Colors, FontSizes, FontWeights, Spaces } from '@stonksfi/constants';

export const StyledPageHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: Colors.black,
  padding: `${Spaces.tiny}`,
  paddingRight: Spaces.large,

  h2: {
    color: Colors.white,
    fontWeight: FontWeights.extrabold,
  },
  svg: {
    color: Colors.white,
    fontSize: FontSizes.subtitle32,
  },
});
