import { styled } from '@stitches/react';
import { Shadows } from '@stonksfi/constants';

export const StyledFlexContainer = styled('div', {
  display: 'flex',
  width: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  position: 'relative',
});

export const StyledFlexElement = styled('div', {
  position: 'relative',
  overflowY: 'auto',
  overflowX: 'hidden',

  variants: {
    shadow: {
      none: {},
      small: {
        boxShadow: Shadows.small,
      },
      medium: {
        boxShadow: Shadows.medium,
      },
      large: {
        boxShadow: Shadows.large,
      },
    },
  },
});
