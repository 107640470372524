import React, { ReactNode } from 'react';
import { StepsContainer, SingleStep } from './styles';

export interface StepsProps {
  totalSteps: number;
  currentStep: number;
  setCurrentStep: (step: number) => void;
}

const Steps = (props: StepsProps) => {
  const { totalSteps, currentStep, setCurrentStep } = props;
  return (
    <StepsContainer>
      {[...Array(totalSteps)].map((_, index) => (
        <SingleStep
          key={index}
          active={index === currentStep}
          onClick={() => setCurrentStep(index)}
        />
      ))}
    </StepsContainer>
  );
};

export default Steps;
