import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { useUserContext } from '@stonksfi/hooks';
import { history } from '@stonksfi/utils';
import { Loader } from '@stonksfi/components';
import DashboardPage from './pages/DashboardPage';
import CoursePage from './pages/CoursePage';
import HomePage from './pages/HomePage';
import { theme, Layout } from './styles/styling';

interface RouteProps {
  path: string;
  exact?: boolean;
  component: any;
}
export const CustomRoute = (props: RouteProps) => {
  const { path, exact, component: Component } = props;
  const { isLoading, accessToken } = useUserContext();
  if ((isLoading || !accessToken) && path !== '/') {
    return <Loader type='background' />;
  }
  return (
    <Route
      path={path}
      exact={exact}
      render={(renderProps: any) => {
        return (
          <Layout className={`${theme}`}>
            <Component {...renderProps} />
          </Layout>
        );
      }}
    />
  );
};

const BaseRouter: React.ReactNode = () => {
  return (
    <Router history={history}>
      <Switch>
        <CustomRoute exact path='/course' component={CoursePage} />
        <CustomRoute exact path='/dashboard' component={DashboardPage} />
        <CustomRoute exact path='/' component={HomePage} />
      </Switch>
    </Router>
  );
};

export default BaseRouter;
