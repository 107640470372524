import { styled, keyframes } from '@stitches/react';
import {
  Colors,
  FontSizes,
  FontWeights,
  Sizes,
  Spaces,
} from '@stonksfi/constants';

export const StepsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: Spaces.medium,
});

export const SingleStep = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: Spaces.small,
  width: Sizes.halfSquareSide,
  height: '5px',
  backgroundColor: Colors.grey6,
  cursor: 'pointer',
  variants: {
    active: {
      true: {
        backgroundColor: Colors.primaryColor9,
      },
    },
  },
});
