/* eslint-disable max-len */
import React, { ReactNode } from 'react';
import { TutorialPlay } from '@stonksfi/assets/icons';
import { ModuleConfig } from '../types';
import { StyledColumn, StyledConceptWrapper, StyledRow } from './style';

interface Props {
  config?: ModuleConfig;
}

const NewsModule = (props: Props) => {
  const { config } = props;
  return (
    <StyledConceptWrapper>
      <StyledRow>
        <StyledColumn>
          <h6>CONCEPT OF THE DAY</h6>
          <h1>Interest Rates</h1>
        </StyledColumn>

        <TutorialPlay width={150} height={150} />
      </StyledRow>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco l
      </p>
    </StyledConceptWrapper>
  );
};

export default NewsModule;
