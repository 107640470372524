import { styled, css } from '@stitches/react';
import {
  Colors,
  FontSizes,
  FontWeights,
  Radius,
  Spaces,
} from '@stonksfi/constants';

export const StyledSideBar = styled('div', {
  background: Colors.white,
  borderRight: `2px solid ${Colors.grey6}`,
  height: '100%',
});

export const StyledSideBarBody = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const StyledNavItem = styled('div', {
  margin: `${Spaces.veryTiny} ${Spaces.small}`,
  fontSize: FontSizes.content14,
  fontWeight: FontWeights.bold,
  padding: `${Spaces.tiny}`,
  paddingRight: `${Spaces.large}`,
  transition: 'all 0.2s',
  display: 'flex',
  alignItems: 'center',

  variants: {
    selected: {
      true: {
        background: Colors.primaryColor4,
        borderRadius: Radius.medium,
        color: Colors.primaryColor8,
      },
      false: {
        '&:hover': {
          cursor: 'pointer',
          background: Colors.primaryColor2,
          borderRadius: Radius.medium,
        },
      },
    },
  },
});

export const StyledPageHeaderLeft = styled('div', {
  marginLeft: Spaces.medium,
  paddingTop: Spaces.small,
  display: 'flex',
  alignItems: 'center',

  h1: {
    marginRight: Spaces.large,
    marginBottom: Spaces.tiny,
    position: 'relative',
  },
});

export const LabelCss = css({
  position: 'absolute',
  top: 10,
  right: -45,
});
