import {
  Color,
  FontSize,
  FontWeight,
  Shadow,
  Space,
  Size,
  Radii,
} from '@stonksfi/types';

// Create an enum for colors
export const Colors: Color = {
  primaryColor1: '$primaryColor1',
  primaryColor2: '$primaryColor2',
  primaryColor3: '$primaryColor3',
  primaryColor4: '$primaryColor4',
  primaryColor5: '$primaryColor5',
  primaryColor6: '$primaryColor6',
  primaryColor7: '$primaryColor7',
  primaryColor8: '$primaryColor8',
  primaryColor9: '$primaryColor9',
  primaryColor10: '$primaryColor10',
  primaryColor11: '$primaryColor11',
  primaryColor12: '$primaryColor12',

  secondaryColor1: '$secondaryColor1',
  secondaryColor2: '$secondaryColor2',
  secondaryColor3: '$secondaryColor3',
  secondaryColor4: '$secondaryColor4',
  secondaryColor5: '$secondaryColor5',
  secondaryColor6: '$secondaryColor6',
  secondaryColor7: '$secondaryColor7',
  secondaryColor8: '$secondaryColor8',
  secondaryColor9: '$secondaryColor9',
  secondaryColor10: '$secondaryColor10',
  secondaryColor11: '$secondaryColor11',
  secondaryColor12: '$secondaryColor12',

  grey1: '$grey1',
  grey2: '$grey2',
  grey3: '$grey3',
  grey4: '$grey4',
  grey5: '$grey5',
  grey6: '$grey6',
  grey7: '$grey7',
  grey8: '$grey8',
  grey9: '$grey9',
  grey10: '$grey10',
  grey11: '$grey11',
  grey12: '$grey12',

  systemOrange: '$systemOrange',
  systemRed: '$systemRed',
  systemGreen: '$systemGreen',
  systemBlue: '$systemBlue',
  systemCream: '$systemCream',
  white: '$white',
  black: '$black',
};

export const Spaces: Space = {
  veryTiny: '$veryTiny',
  tiny: '$tiny',
  small: '$small',
  medium: '$medium',
  big: '$big',
  large: '$large',
};

export const FontSizes: FontSize = {
  title54: '$title54',
  title46: '$title46',

  subtitle40: '$subtitle40',
  subtitle38: '$subtitle38',
  subtitle32: '$subtitle32',
  subtitle28: '$subtitle28',
  subtitle24: '$subtitle24',
  subtitle20: '$subtitle20',

  content18: '$content18',
  content16: '$content16',
  content14: '$content14',
  content12: '$content12',
  content10: '$content10',
};

export const FontWeights: FontWeight = {
  light: '$light',
  regular: '$regular',
  medium: '$medium',
  semibold: '$semibold',
  bold: '$bold',
  extrabold: '$extrabold',
};

export const Shadows: Shadow = {
  small: '$small',
  medium: '$medium',
  large: '$large',
};

export const Sizes: Size = {
  cardWidth: '$cardWidth',
  cardHeight: '$cardHeight',

  bigCardWidth: '$bigCardWidth',
  bigCardHeight: '$bigCardHeight',

  buttonMinWidth: '$buttonMinWidth',
  buttonMinHeight: '$buttonMinHeight',

  squareSide: '$squareSide',
  halfSquareSide: '$halfSquareSide',
};

export const Radius: Radii = {
  tiny: '$tiny',
  small: '$small',
  medium: '$medium',
  big: '$big',
  large: '$large',
};
