import { styled, keyframes } from '@stitches/react';
import {
  Colors,
  FontSizes,
  FontWeights,
  Sizes,
  Spaces,
} from '@stonksfi/constants';

export const ContentGuideContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  gap: Spaces.medium,
  padding: `${Spaces.large}`,
  overflowY: 'scroll',

  h2: {
    height: '70%',
    fontSize: FontSizes.subtitle32,
    fontWeight: FontWeights.semibold,
    lineHeight: '40px',
  },
});

export const ContentGuideImage = styled('img', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '30%',
  objectFit: 'contain',
});
