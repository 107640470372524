import { styled, css } from '@stitches/react';
import { Colors } from '@stonksfi/constants';

export const StyledLine = styled('div', {
  zIndex: 0,
  borderTop: '5px dashed',
  variants: {
    color: {
      $primaryColor1: {
        borderColor: `${Colors.primaryColor1}`,
      },
      $primaryColor2: {
        borderColor: `${Colors.primaryColor2}`,
      },
      $primaryColor3: {
        borderColor: `${Colors.primaryColor3}`,
      },
      $primaryColor4: {
        borderColor: `${Colors.primaryColor4}`,
      },
      $primaryColor5: {
        borderColor: `${Colors.primaryColor5}`,
      },
      $primaryColor6: {
        borderColor: `${Colors.primaryColor6}`,
      },
      $primaryColor7: {
        borderColor: `${Colors.primaryColor7}`,
      },
      $primaryColor8: {
        borderColor: `${Colors.primaryColor8}`,
      },
      primaryColor9: {
        borderColor: `${Colors.primaryColor9}`,
      },
      $primaryColor10: {
        borderColor: `${Colors.primaryColor10}`,
      },
      $primaryColor11: {
        borderColor: `${Colors.primaryColor11}`,
      },
      $primaryColor12: {
        borderColor: `${Colors.primaryColor12}`,
      },
      $grey1: {
        borderColor: `${Colors.grey1}`,
      },
      $grey2: {
        borderColor: `${Colors.grey2}`,
      },
      $grey3: {
        borderColor: `${Colors.grey3}`,
      },
      $grey4: {
        borderColor: `${Colors.grey4}`,
      },
      $grey5: {
        borderColor: `${Colors.grey5}`,
      },
      $grey6: {
        borderColor: `${Colors.grey6}`,
      },
      $grey7: {
        borderColor: `${Colors.grey7}`,
      },
      $grey8: {
        borderColor: `${Colors.grey8}`,
      },
      $grey9: {
        borderColor: `${Colors.grey9}`,
      },
      $grey10: {
        borderColor: `${Colors.grey10}`,
      },
      $grey11: {
        borderColor: `${Colors.grey11}`,
      },
      $grey12: {
        borderColor: `${Colors.grey12}`,
      },
    },
  },
});
