import React from 'react';
import { Icons } from '@stonksfi/assets';
import { GameIcon, LineBetweenIcons } from '@stonksfi/components';
import { useQuery } from 'react-query';
import { ModuleConfig } from '../types';
import { ParentClassName } from './style';

interface Props {
  config?: ModuleConfig;
}

const TopicProgressModule = (props: Props) => {
  const { config } = props;
  const data = useQuery('/v1/education/course');
  const TOPICS = [
    <GameIcon
      iconWrapperStyle={{ alignSelf: 'flex-start', zIndex: 1 }}
      icon={<Icons.Level1 width={150} height={150} />}
      type='default'
    />,
    <GameIcon
      iconWrapperStyle={{ alignSelf: 'flex-end', zIndex: 1 }}
      icon={<Icons.Level2 width={150} height={150} />}
      type='floating'
      action='CONTINUE'
    />,
    <GameIcon
      iconWrapperStyle={{ alignSelf: 'flex-start', zIndex: 1 }}
      icon={<Icons.Level1 width={150} height={150} />}
      type='disabled'
    />,
    <GameIcon
      iconWrapperStyle={{ alignSelf: 'flex-end', zIndex: 1 }}
      icon={<Icons.Level1 width={150} height={150} />}
      type='disabled'
    />,
  ];
  return (
    <LineBetweenIcons content={TOPICS} parentClassName={ParentClassName()} />
  );
};

export default TopicProgressModule;
