/* eslint-disable max-len */
import React, { ReactNode } from 'react';
import { ModuleConfig } from '../types';
import { NewsImage, StyledNewsWrapper } from './style';

interface Props {
  config?: ModuleConfig;
}

const NewsModule = (props: Props) => {
  const { config } = props;
  return (
    <StyledNewsWrapper>
      <h6>NEWS OF THE DAY</h6>
      <NewsImage
        src='https://media4.s-nbcnews.com/j/newscms/2019_01/2705191/nbc-social-default_b6fa4fef0d31ca7e8bc7ff6d117ca9f4.nbcnews-fp-1024-512.png'
        alt='News Image'
      />
      <h2>Fed raises interest rate by 10%</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco l
      </p>
    </StyledNewsWrapper>
  );
};

export default NewsModule;
