import { styled } from '@stitches/react';
import { Colors, Spaces } from '@stonksfi/constants';

export const StyledRow = styled('div', {
  marginTop: Spaces.small,
  padding: `0 ${Spaces.large}`,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  '> button': {
    marginLeft: Spaces.small,
  },
});

export const StyledTabHeader = styled('div', {
  marginTop: Spaces.small,
  display: 'flex',
  paddingLeft: Spaces.large,
  paddingBottom: Spaces.tiny,
  borderBottom: `${Colors.grey6} 1px solid`,
});
