import React from 'react';
import { Layout } from '@stonksfi/components';
import { mainConfig } from './configs/main';

const DashboardPage = () => {
  return (
    <>
      <Layout settings={mainConfig} />
    </>
  );
};

export default DashboardPage;
