import { styled, css } from '@stitches/react';
import { Colors, Radius, Shadows, Sizes, Spaces } from '@stonksfi/constants';

export const StyledConnector = styled('div', {
  position: 'relative',
  width: '100px',
  height: '100px',
  overflow: 'visible',
});

export const ParentClassName = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});
