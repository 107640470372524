import React from 'react';

import { Whisper, Popover as Component } from 'rsuite';
import { StyledPopover, StyledTooltipTrigger } from './style';

interface PopoverProps {
  children: React.ReactNode;
  title: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  arrow?: boolean;
  onClick?: (e?: any) => void;
  content: React.ReactNode | string;
  triggerStyle?: React.CSSProperties;
  open: boolean;
}

const DefaultPopover = React.forwardRef(
  ({ content, title, arrow, onClick, ...props }: any, ref: any) => {
    return (
      <Component
        ref={ref}
        title={title}
        arrow={arrow}
        style={{ background: 'red' }}
        {...props}
      >
        <StyledPopover ref={ref} onClick={onClick}>
          {typeof content === 'string' ? <p>{content}</p> : content}
        </StyledPopover>
      </Component>
    );
  }
);

const Popover = (props: PopoverProps) => {
  const {
    children,
    title,
    placement,
    content,
    triggerStyle,
    open,
    onClick,
    arrow = false,
  } = props;
  return (
    <Whisper
      open={open}
      placement={placement}
      speaker={
        <DefaultPopover
          content={content}
          title={title}
          arrow={arrow}
          onClick={onClick}
        />
      }
      delayClose={200}
      enterable
    >
      <StyledTooltipTrigger style={triggerStyle}>
        {children}
      </StyledTooltipTrigger>
    </Whisper>
  );
};

export default Popover;
