import { styled } from '@stitches/react';
import { Colors, Radius, Shadows, Sizes, Spaces } from '@stonksfi/constants';

export const StyledDailyTasks = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const StyledRow = styled('div', {
  height: Sizes.squareSide,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  // Add gap between each item
  gap: Spaces.tiny,
});

export const StyledRowWithSpaceBetween = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: Spaces.medium,
});
