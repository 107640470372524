import { styled, keyframes } from '@stitches/react';
import {
  Colors,
  FontSizes,
  FontWeights,
  Sizes,
  Spaces,
} from '@stonksfi/constants';

const floating = keyframes({
  '0%': {
    transform: 'translateY(0px)',
  },
  '50%': {
    transform: 'translateY(5px)',
  },
  '100%': {
    transform: 'translateY(0px)',
  },
});

export const StyledAction = styled('div', {
  fontSize: FontSizes.content14,
  fontWeight: FontWeights.bold,
  color: Colors.primaryColor12,
  padding: `0 ${Spaces.small}`,
});

export const StyledGameIcon = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  variants: {
    type: {
      default: {},
      disabled: {
        filter: 'blur(5px)',
        pointerEvents: 'none',
      },
      floating: {
        animation: `${floating} 4s ease-in-out infinite`,
      },
    },
  },
});
