import React, { ReactNode } from 'react';
/* Styles */
import Popover from '../Popover';
import { StyledAction, StyledGameIcon } from './styles';

export interface GameIconProps {
  icon: ReactNode;
  type: 'default' | 'disabled' | 'floating';
  iconWrapperStyle?: any;
  action?: string;
  onClick?: (e?: any) => void;
}

const GameIcon = (props: GameIconProps) => {
  const { icon, type, iconWrapperStyle, action, onClick } = props;
  if (action) {
    return (
      <Popover
        content={<StyledAction>{action}</StyledAction>}
        title=''
        placement='top'
        triggerStyle={iconWrapperStyle}
        open
        arrow
        onClick={onClick}
      >
        <StyledGameIcon type={type}>{icon}</StyledGameIcon>
      </Popover>
    );
  } else {
    return (
      <StyledGameIcon type={type} style={iconWrapperStyle} onClick={onClick}>
        {icon}
      </StyledGameIcon>
    );
  }
};

export default GameIcon;
