import React, { ReactNode } from 'react';

/* Styles */
import { StyledIconText, StyledSquareButton } from './styles';

export type ButtonSize = 'small';

interface ButtonProps {
  icon: string | ReactNode;
  children: ReactNode;
  onClick?: (e?: any) => void;
  size?: ButtonSize;
  className?: string;
}

const SquareButton = (props: ButtonProps) => {
  const { children, onClick, className, size = 'small', icon } = props;
  return (
    <StyledSquareButton size={size} onClick={onClick} className={className}>
      {typeof icon === 'string' ? (
        <StyledIconText>{icon}</StyledIconText>
      ) : (
        icon
      )}
      {children}
    </StyledSquareButton>
  );
};

export default SquareButton;
