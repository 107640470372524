import React, { ReactNode } from 'react';
import { ContentGuideContainer, ContentGuideImage } from './styles';

export interface ContentGuideProps {
  contentGuideType: 'default';
  contentImg?: string;
  content: string;
}

const ContentGuide = (props: ContentGuideProps) => {
  const { contentGuideType, contentImg, content } = props;
  return (
    <ContentGuideContainer>
      {contentImg ? <ContentGuideImage src={contentImg} alt={content} /> : null}
      <h2>{content}</h2>
    </ContentGuideContainer>
  );
};

export default ContentGuide;
