import * as React from 'react';
import { SideNav } from '@stonksfi/components';
import { SidenavOption } from '@stonksfi/components/SideNav';
import { Icons } from '@stonksfi/assets';

const SideBar = () => {
  const NAV_OPTIONS: SidenavOption[] = [
    {
      name: 'LEARN',
      path: '/',
      icon: <Icons.NavHome width={50} height={50} />,
    },
    {
      name: 'INVEST',
      path: '/invest',
      icon: <Icons.NavInvest width={50} height={50} />,
    },
    {
      name: 'PROFILE',
      path: '/profile',
      icon: <Icons.NavLearn width={50} height={50} />,
    },
  ];
  // const { logout, isAuthenticated, accessToken } = useUserContext();
  return <SideNav options={NAV_OPTIONS} />;
};

export default SideBar;
