import { styled } from '@stitches/react';
import { Colors, Radius, Shadows, Sizes, Spaces } from '@stonksfi/constants';

export const StyledConceptWrapper = styled('div', {
  background: Colors.primaryColor3,
  padding: Spaces.big,
  border: `1px solid ${Colors.grey6}`,
  borderRadius: Radius.tiny,
  boxShadow: Shadows.large,

  h6: {
    color: Colors.primaryColor12,
  },

  p: {
    color: Colors.primaryColor11,
  },
});

export const StyledRow = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  position: 'relative',
});

export const StyledColumn = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  h1: {
    marginTop: Spaces.medium,
  },
});

export const NewsImage = styled('img', {
  width: '100%',
  height: Sizes.cardHeight,
  objectFit: 'contain',
});
