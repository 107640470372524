import React, { useRef, useEffect, useState } from 'react';
import { Colors } from '@stonksfi/constants';
import { StyledLine } from './styles';

interface Props {
  parentClassName?: string;
  color: 'grey9' | 'primaryColor9';
  content: React.ReactNode[];
}
const LineBetweenComponents = (props: Props) => {
  const { parentClassName, content, color = 'primaryColor9' } = props;
  const parentRef = useRef<any>();
  const [componentPositions, setComponentPositions] = useState<any[]>([]);

  useEffect(() => {
    // Function to calculate the positions of all components
    const calculateComponentPositions = () => {
      if (parentRef.current) {
        // Get the positions of all child components
        let childElements: HTMLElement[] =
          Array.from(parentRef?.current.children) || [];
        childElements = childElements.filter(
          (child) => !child.id.includes('line')
        );
        const componentPositions = childElements.map((element: HTMLElement) => {
          const rect = element.getBoundingClientRect();
          const left = element.offsetLeft;
          const top = element.offsetTop;
          return {
            x: left + rect.width / 2,
            y: top + rect.height / 2,
          };
        });

        // Calculate the width and angle of the lines between components
        const updatedPositions = [];
        for (let i = 0; i < componentPositions.length - 1; i++) {
          const currPos = componentPositions[i];
          const nextPos = componentPositions[i + 1];
          const width = Math.sqrt(
            (nextPos.x - currPos.x) ** 2 + (nextPos.y - currPos.y) ** 2
          );
          const angle =
            (Math.atan2(nextPos.y - currPos.y, nextPos.x - currPos.x) * 180) /
            Math.PI;

          updatedPositions.push({
            ...currPos,
            width,
            angle,
          });
        }

        return updatedPositions;
      } else {
        return [];
      }
    };

    // Calculate positions after components are mounted
    const positions = calculateComponentPositions();

    // Update the line coordinates state
    // You can store this state in a parent component or use context if needed
    setComponentPositions(positions);
  }, []);

  return (
    <div
      style={{ position: 'relative' }}
      ref={parentRef}
      className={parentClassName}
    >
      {content.map((child) => child)}
      {componentPositions.map((pos: any, index) => (
        <StyledLine
          key={index}
          id='line'
          color={color}
          style={{
            position: 'absolute',
            top: `${pos.y}px`,
            left: `${pos.x}px`,
            width: pos.width ? `${pos.width}px` : '0',
            height: '0',
            transform: `rotate(${pos.angle}deg)`,
            transformOrigin: '0 0',
          }}
        />
      ))}
    </div>
  );
};

export default LineBetweenComponents;
