import { styled } from '@stitches/react';
import { Colors, Radius, Shadows, Sizes, Spaces } from '@stonksfi/constants';

export const StyledNewsWrapper = styled('div', {
  background: Colors.primaryColor3,
  padding: Spaces.big,
  border: `1px solid ${Colors.grey6}`,
  borderRadius: Radius.tiny,
  boxShadow: Shadows.large,
  height: '100%',

  h6: {
    color: Colors.primaryColor12,
  },

  p: {
    marginTop: Spaces.small,
    color: Colors.primaryColor11,
  },
});

export const NewsImage = styled('img', {
  width: '100%',
  height: '100px',
  objectFit: 'cover',
  margin: `${Spaces.tiny} 0`,
});
