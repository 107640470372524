import * as React from 'react';
import { useUserContext } from '@stonksfi/hooks';
import { RxCross2 } from 'react-icons/rx';
import { history } from '@stonksfi/utils';
import { Button } from '@stonksfi/components';
import { StyledPageHeader } from './style';

interface Props {
  title?: string;
  callToAction?: any;
}

const PageHeader = (props: Props) => {
  const { title, callToAction } = props;
  const { logout, isAuthenticated } = useUserContext();
  return (
    <>
      <title>stonks.fi -{title}</title>

      <StyledPageHeader>
        {/* {isAuthenticated ? (
          <Button
            type='black'
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            <MdLogout />
            &nbsp;Logout
          </Button>
        ) : null} */}
        <Button type='link' onClick={() => history.goBack()}>
          <RxCross2 />
        </Button>
        <h2>stonks.fi</h2>
      </StyledPageHeader>
    </>
  );
};

export default PageHeader;
