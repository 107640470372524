// Base imports
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import {
  AlertsContextProvider,
  DashboardMetaContextProvider,
  DndContextProvider,
  UserContextProvider,
  useUserContext,
} from '@stonksfi/hooks';
import {
  QueryClient,
  QueryClientProvider,
  QueryFunctionContext,
} from 'react-query';
import { QueryClientProviderWithAuth } from '@stonksfi/hooks/useQueryClient';
import BaseRouter from './routes';
import 'rsuite/dist/rsuite.min.css';
// Import css to remove margin
import './index.scss';
import { BACKEND } from './config';

const App = () => {
  return (
    <Auth0Provider
      domain='dev-j5rnqli2ch728y7j.us.auth0.com'
      clientId='3YSRCXBYqG5kcTumedLqXVfeUgX5faUQ'
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: 'http://api.stonks.fi',
      }}
    >
      <UserContextProvider>
        <AlertsContextProvider>
          <DashboardMetaContextProvider>
            <DndContextProvider>
              <QueryClientProviderWithAuth>
                {/* @ts-ignore */}
                <BaseRouter />
              </QueryClientProviderWithAuth>
            </DndContextProvider>
          </DashboardMetaContextProvider>
        </AlertsContextProvider>
      </UserContextProvider>
    </Auth0Provider>
  );
};

export default App;
